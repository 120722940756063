"use client";
import React, {useState, useEffect} from "react";

import styles from "./mev.module.css";
import {
    Chart as ChartJs,
    ArcElement,
    Tooltip,
    Legend,
    Colors,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import "chartjs-plugin-style";
import {Line} from "react-chartjs-2";

import {useLineData, getLineOptions, verticalLinePlugin} from "./LineConfig";
import {
    fetchPortfolioTrackRecordsRoundFourDigit,
    fetchadjustDailyPortfolioData,
} from "./fetchDailyPortfolioData";
import arrowImg from "../static_files/Arrow 7.svg";
import arrowDownImg from "../static_files/Arrow 7 down.svg"

ChartJs.register(
    ArcElement,
    Tooltip,
    Legend,
    Colors,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    TimeScale
);

const LineChart = ({
                       trackerData,
                       chartBgColor = "#060F11",
                       maxWidth = "527px",
                       availableUSDI,
                       usdiValue,
                   }) => {
    const [selectedRange, setSelectedRange] = useState("ALL");
    const [lineOptions, setLineOptions] = useState({});
    const [pieData, setPieData] = useState(null);
    const [pieOptions, setPieOptions] = useState(null);
    const [dollarData, setDollarData] = useState(null);

    // For showing "greenGain" percentage
    const [timeChanges, setTimeChanges] = useState({
        oneMonth: 0,
        threeMonth: 0,
        oneYear: 0,
        allTime: 0,
    });

    // Pass selectedRange to useLineData
    const lineChartData = useLineData(selectedRange);

    const containerStyle = {
        maxWidth: maxWidth,
    };

    /**
     * Given the data returned by fetchadjustDailyPortfolioData(), compute the percentage
     * changes for 1M, 3M, 1Y, and ALL based on data.datasets4.
     *
     * Returns an object with { oneMonth, threeMonth, oneYear, allTime }.
     */
    function calculateTimeRangeChanges(data) {
        // 1) Extract the array from the data
        const values = data.datasets4; // array of strings, 0 => newest, last => oldest
        if (!values || values.length === 0) {
            return {
                oneMonth: 0,
                threeMonth: 0,
                oneYear: 0,
                allTime: 0,
            };
        }
        const parsedValues = values.map((val) => parseFloat(val));
        const todayValue = parsedValues[0];
        const earliestValue = parsedValues[parsedValues.length - 1];

        function getValueDaysAgo(days) {
            const targetIndex = days;
            // If we don't have that many data points, use earliest
            if (targetIndex >= parsedValues.length) {
                return earliestValue;
            }
            return parsedValues[targetIndex];
        }

        const monthValue = getValueDaysAgo(31);      // ~1 month ago
        const threeMonthValue = getValueDaysAgo(89); // ~3 months ago
        const oneYearValue = getValueDaysAgo(365);   // ~1 year ago

        function percentChange(oldVal, newVal) {
            if (!oldVal || oldVal === 0) return 0;
            return (newVal - oldVal) / oldVal;
        }

        return {
            oneMonth: percentChange(monthValue, todayValue),
            threeMonth: percentChange(threeMonthValue, todayValue),
            oneYear: percentChange(oneYearValue, todayValue),
            allTime: percentChange(earliestValue, todayValue),
        };
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                [trackerData] = await Promise.all([
                    fetchPortfolioTrackRecordsRoundFourDigit(),
                ]);
                setDollarData((trackerData.dollar_value_growth - 1) / 0.01);
                setPieData(pieData);
                setPieOptions(pieOptions);

                const dailyData = await fetchadjustDailyPortfolioData();
                const changes = calculateTimeRangeChanges(dailyData);
                setTimeChanges(changes);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // Pass both window.innerWidth and selectedRange to getLineOptions
    useEffect(() => {
        const updateOptions = () => {
            setLineOptions(getLineOptions(window.innerWidth, selectedRange));
        };
        updateOptions();

        window.addEventListener("resize", updateOptions);
        return () => window.removeEventListener("resize", updateOptions);
    }, [selectedRange]);

    // Decide which timeChanges property to display
    let displayedChange = 0;
    if (selectedRange === "1M") {
        displayedChange = timeChanges.oneMonth;
    } else if (selectedRange === "3M") {
        displayedChange = timeChanges.threeMonth;
    } else if (selectedRange === "1Y") {
        displayedChange = timeChanges.oneYear;
    } else {
        displayedChange = timeChanges.allTime; // "ALL"
    }

    return (
        <div className={styles.container} style={containerStyle}>
            {/* Buttons row */}
            <div className={styles.mevTop}>
                <div className={styles.balanceBox}>
                    <h2 className={styles.availableBalance}>
                        {availableUSDI && usdiValue
                            ? `$${(availableUSDI * usdiValue).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}`
                            : "--"}
                    </h2>

                    {/* Conditionally swap arrow image */}
                    <img
                        src={displayedChange < 0 ? arrowDownImg : arrowImg}
                        style={{background: "#1c1c1c"}}
                        alt="arrow"
                    />

                    {/* Conditionally set color and absolute value if negative */}
                    <p className={displayedChange < 0 ? styles.redLoss : styles.greenGain}>
                        {Math.abs(displayedChange * 100).toFixed(2)}%
                    </p>
                </div>

                <div
                    className={styles.topMonthButtonContainer}
                    style={{
                        height: "21.24px",
                        marginTop: "30px",
                        background: "#1c1c1c",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "right",
                    }}
                >
                    <div
                        className={styles.monthButton}
                        onClick={() => setSelectedRange("1M")}
                        style={{
                            background: selectedRange === "1M" ? "#1A2B21" : "inherit",
                        }}
                    >
                        1M
                    </div>
                    <div
                        className={styles.monthButton}
                        onClick={() => setSelectedRange("3M")}
                        style={{
                            background: selectedRange === "3M" ? "#1A2B21" : "inherit",
                        }}
                    >
                        3M
                    </div>
                    <div
                        className={styles.monthButton}
                        onClick={() => setSelectedRange("1Y")}
                        style={{
                            background: selectedRange === "1Y" ? "#1A2B21" : "inherit",
                        }}
                    >
                        1Y
                    </div>
                    <div
                        className={styles.monthButton}
                        onClick={() => setSelectedRange("ALL")}
                        style={{
                            background: selectedRange === "ALL" ? "#1A2B21" : "inherit",
                        }}
                    >
                        ALL
                    </div>
                </div>
            </div>

            <Line
                style={{backgroundColor: chartBgColor}}
                data={lineChartData}
                options={lineOptions}
                plugins={[verticalLinePlugin]}
            />

            <div
                className={styles.lowerMonthButtonContainer}
                style={{
                    height: "21.24px",
                    marginTop: "30px",
                    background: "#1c1c1c",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
            >
                <div
                    className={styles.monthButton}
                    onClick={() => setSelectedRange("1M")}
                    style={{
                        background: selectedRange === "1M" ? "#1A2B21" : "inherit",
                    }}
                >
                    1M
                </div>
                <div
                    className={styles.monthButton}
                    onClick={() => setSelectedRange("3M")}
                    style={{
                        background: selectedRange === "3M" ? "#1A2B21" : "inherit",
                    }}
                >
                    3M
                </div>
                <div
                    className={styles.monthButton}
                    onClick={() => setSelectedRange("1Y")}
                    style={{
                        background: selectedRange === "1Y" ? "#1A2B21" : "inherit",
                    }}
                >
                    1Y
                </div>
                <div
                    className={styles.monthButton}
                    onClick={() => setSelectedRange("ALL")}
                    style={{
                        background: selectedRange === "ALL" ? "#1A2B21" : "inherit",
                    }}
                >
                    ALL
                </div>
            </div>
        </div>
    );
};

export default LineChart;
