
// ERC20 Token Distributor Address on different chains
//      Make sure chainId is in LOWERCASE
const distributor = new Map([
    ['0x7a69', ''], // Hardhat test, update when testing locally
    ['0xaa36a7', '0x46769A95638917E755a9dF202D91934cb91DC6aD'] // Sepolia
]);

const getDistributorAddr = (chainId) => {
    if (distributor.has(chainId)) {
        return distributor.get(chainId);
    }
    return null;
}

export default getDistributorAddr;
