import React, {useState} from 'react';
import {NavLink, Route, Routes} from 'react-router-dom';
import TrackRecords from './tables/TrackRecords';
import Portfolio from './tables/Portfolio';
import Monitor from './tables/Monitor';
import './App.css';
import Header from "./tables/header/Header";
import USDIStats from './tables/USDIStats';
import { TokenManagement } from './tables/TokenManagement';
import Swap from "./tables/Swap";
const HomePage = () => {

    return (
        <div>
            <div className="content">
                <Routes>
                    <Route path="token-management" element={<TokenManagement/>}/>
                    <Route path="track-records" element={<TrackRecords/>}/>
                    <Route path="portfolio" element={<Portfolio/>}/>
                    <Route path="monitor" element={<Monitor/>}/>
                     <Route path="swap" element={<Swap/>}/>
                    <Route path="usdi-stats" element={<USDIStats/>}/>
                </Routes>
            </div>
        </div>
    );
};

export default HomePage;
