import React, {useState} from 'react';
import styles from './UserHeader.module.css';
import logo_svg from '../static_files/logo-nobackground.svg';
import user_icon from '../static_files/Subtract.svg';
import {useAuth} from '../../AuthContext';
import logout_svg from "../static_files/logout.svg"
import setting_svg from "../static_files/settings.svg"
import invite_svg from "../static_files/invite.svg"

const UserHeader = () => {
    const [showPanel, setShowPanel] = useState(false);
    const {isGatewayAuthenticated, gatewayUserData, logout} = useAuth() || {};

    const handleUserIconClick = () => {
        setShowPanel((prev) => !prev);
    };

    const closeSidebar = () => {
        setShowPanel(false);
    };

    const handleLogoutClick = () => {
        logout();
        // window.location.href = "https://gateway.0max1.com/";
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    // Close the panel if the user clicks anywhere else on the screen
    const handleOutsideClick = () => {
        if (showPanel) {
            closeSidebar();
        }
    };

    return (
        <div className={styles.outerContainer} onClick={handleOutsideClick}>
            <div className={styles.container}>
                <a href="https://0max1.com/" target="_blank" rel="noreferrer">
                    <img className={styles.vectorIcon} alt="" src={logo_svg}/>
                </a>
                <div className={styles.userIconContainer} onClick={(e) => e.stopPropagation()}>
                    <img
                        className={styles.userIcon}
                        alt="User Icon"
                        src={user_icon}
                        onClick={handleUserIconClick}
                    />

                    {/* Overlay appears when panel is open */}
                    {showPanel && (
                        <div
                            className={styles.overlay}
                            onClick={handleOutsideClick}
                        ></div>
                    )}

                    <div
                        className={`${styles.sidePanel} ${showPanel ? styles.open : ''}`}
                        onClick={stopPropagation}
                    >
                        {isGatewayAuthenticated && gatewayUserData ? (
                            <div className={styles.userInfo}>
                                <div className={styles.userEmail}>
                                    <img
                                        className={styles.userIcon2}
                                        alt="User Icon"
                                        src={user_icon}
                                    />
                                    <h3>{gatewayUserData.email}</h3>
                                </div>
                                <div className={styles.divider}></div>
                                <div className={styles.inviteFriends}>
                                    <img src={invite_svg} alt="img"/>
                                    <p onClick={() => alert("Developing")}>Invite Friends</p>
                                </div>
                                <div className={styles.inviteFriends}>
                                    <img src={setting_svg} alt="img"/>
                                    <p onClick={() => alert("Developing")}>Settings</p>
                                </div>
                                <div className={styles.inviteFriends}>
                                    <img src={logout_svg} alt="img"/>
                                    <p onClick={handleLogoutClick}>Logout</p>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.userInfo}>
                                <h3>Admin user</h3>
                                <div className={styles.logoutBtnWrapper}>
                                    <button className={styles.logoutBtn} onClick={handleLogoutClick}>
                                        Logout
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserHeader;
