import React, {useState, useEffect} from 'react';
import styles from './ConnectWalletHeader.module.css';
import {raydiumSwap} from './raydiumSwap/raydium-swap'
import email_svg from '../static_files/connect-email.svg';
import okx_icon from '../static_files/OKX-icon.svg';
import coinbase_icon from '../static_files/Coinbase-icon.svg';
import walletconnect_icon from '../static_files/walletconnect-icon.svg';
import menu_icon from "../static_files/swap_main_menu_mobile_icon.svg";
import logo_svg from "../static_files/logo-nobackground.svg";
import rejectImg from '../static_files/rejectImg.svg';
import cancle_icon from '../static_files/cancel.svg';
import arrowDownImg from '../static_files/arrow_down_no_background.svg';
import successImg from '../static_files/successImg.svg';
import disconnectImg from '../static_files/disconnect.svg';
import {
    fetchBalancesAndNotifyParent,
    getTokenAccountFromWalletAddress,
    sleep2,
    sleep
} from '../header/ConnectWalletHeaderOnchainHelper'
import {
    Connection,
    PublicKey,
    Transaction,
} from '@solana/web3.js';
import {
    getOrCreateAssociatedTokenAccount,
    createTransferInstruction,
    TOKEN_PROGRAM_ID
} from '@solana/spl-token';
import SideMenu from "./MobileMenu";

/**
 * Props:
 *  - amount: user input mint/redeem amount 输入金额
 *  - transactionTrigger: to trigger transaction by pressing the button 是否触发
 *  - toAddress: 默认是到squads vault 的账户
 *  - onWalletConnected : 用来传出 balance
 *  - onSignature：暂时不需要
 *  - selectedToken:
 *  - transactionType
 *  - externalIsError : 外部是否有输入错误
 *  - externalErrorMessage: 错误信息
 *  - transactionTime: 发生交易的时间
 */
const ConnectWalletHeader = ({
                                 amount,
                                 transactionTrigger,
                                 toAddress,
                                 onWalletConnected,
                                 onSignature,
                                 selectedToken,
                                 transactionType,
                                 externalIsError,        // boolean: e.g. true if parent wants to show an error
                                 externalErrorMessage,    // string: e.g. "Insufficient funds"
                                 transactionTime
                             }) => {

    // console.log("to address", toAddress)


    const [showPanel, setShowPanel] = useState(false);
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState('myAssets');
    const [walletAddress, setWalletAddress] = useState("Connect Wallet");

    // For OKX (unchanged)
    const [connectedPublicKey, setConnectedPublicKey] = useState(null);
    const [okxSolanaProvider, setOkxSolanaProvider] = useState(null);

    // NEW: track which wallet is connected
    const [connectedWalletType, setConnectedWalletType] = useState(null);
    const [coinbaseSolanaProvider, setCoinbaseSolanaProvider] = useState(null);
    // error Connect WalletHeader plus outside passed in  to combine with final
    const [transactionMessage, setTransactionMessage] = useState(null);
    const [isTransactionError, setIsTransactionError] = useState(false);
    const finalMessage = externalErrorMessage ?? transactionMessage;
    const [finalIsError, setFinalIsError] = useState()
    // For cash redeem transaction ID
    const [cashRedeemTransactionID, setCashRedeemTransactionID] = useState(null);


    // const RPC_URL = "https://rpc.ankr.com/solana/80465c767c6a5751c4cadf0778b6917d26c2d0a9ae583d3616ffaae71191a7c4";
    const RPC_URL = "https://solitary-boldest-thunder.solana-mainnet.quiknode.pro/7e14722814e9fc29ad81532c2da66c2ffbe3781f"
    const USDT_MINT_ADDRESS = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB';
    const USDC_MINT_ADDRESS = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v';
    const USDI_MINT_ADDRESS = 'CXbKtuMVWc2LkedJjATZDNwaPSN6vHsuBGqYHUC4BN3B';

    // -----------------------------------------
    // Use Effect
    // -----------------------------------------
    // Handel Transfer trigger ->
    // 1. cash redeem,
    // 2. amount > 5000 mint/redeem
    // 3. amount <= 5000
    useEffect(() => {
        if (selectedToken === 'CASH') {
            const getC2CVaultAddress = async () => {
                try {
                    const response = await fetch("https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/get_c2c_receiving_address", {
                        method: 'GET',
                        headers: {'Content-Type': 'application/json'},

                    });
                    if (!response.ok) {
                        throw new Error(`Server responded with ${response.status}`);
                    }
                    const data = await response.json();
                    // its actually the data.
                    const C2C_USDI_RECEIVING_ADDRESS = data.verified_token_solana_usdt;
                    // const C2C_USDI_RECEIVING_ADDRESS = data.c2c_usdi_receiving_squads_address;

                    const C2C_USDI_RECEIVING_TOKEN_ACCOUNT = await getTokenAccountFromWalletAddress(C2C_USDI_RECEIVING_ADDRESS)
                    console.log("C2C_USDI_RECEIVING_TOKEN_ACCOUNT")
                    handleSolanaTransaction(C2C_USDI_RECEIVING_TOKEN_ACCOUNT);

                } catch (err) {
                    console.error('Error recording CASH transaction:', err);
                    setIsTransactionError(true);
                    setTransactionMessage(`CASH flow failed: ${err.message}`);
                }
            };
            getC2CVaultAddress();
            // should be greater than 5000
        } else if (Number(amount) >= 5000) {
            //use squads contract send to back end
            console.log("using squads for now")
            handleSolanaTransaction();
        } else if (Number(amount) > 0 && transactionTrigger) {
            //Raydium swap
            async function doRaydiumSwap() {
                if (!connectedPublicKey) return;
                let providerToUse = null;
                console.log(" raydium discide to use ", connectedWalletType)

                if (connectedWalletType === 'okx') {
                    providerToUse = okxSolanaProvider;
                } else if (connectedWalletType === 'coinbase') {
                    providerToUse = coinbaseSolanaProvider;
                }
                if (!providerToUse) return;
                const rc = (await import('./raydiumSwap/raydium-config.js')).default;

                // 2) 设置 ownerAddress 和 signAllTransactions
                rc.ownerAddress = new PublicKey(connectedPublicKey);
                console.log("before setting signalltransactions")
                rc.signAllTransactions = async (txs) => {
                    console.log('Raydium 请求 signAllTransactions，:', txs.length);
                    const signedTxs = [];
                    for (const tx of txs) {
                        console.log("tx here !!!!", tx)
                        console.log("tx here message!!!!", tx.message)

                        const signed = await providerToUse.signTransaction(tx);
                        console.log("signed result@@@@@@@@@@@@@@", signed)
                        signedTxs.push(signed);
                    }

                    return signedTxs;
                };
                console.log("after setting signalltransactions")

                // 3) 初始化 Raydium SDK
                await rc.initSdk();

                // 4) 再 import 你的 swap 函数
                const {raydiumSwap} = await import('./raydiumSwap/raydium-swap.js');

                // 5) 执行 swap，并“拿到返回结果”
                try {

                    const result = await raydiumSwap({
                        transactionType,
                        tokenType: selectedToken, // "usdt" or "usdc"
                        amount: Number(amount)
                    });
                    if (result.success) {

                        // ======== Swap 成功 ========
                        const txId = result.txId;
                        const explorerLink = `https://solscan.io/tx/${txId}`;

                        setIsTransactionError(false);
                        setTransactionMessage(
                            `${transactionType} of ${amount} ${selectedToken.toUpperCase()} successfully submitted.
                         <a href="${explorerLink}" target="_blank" rel="noreferrer">Check on Explorer</a>`
                        );
                    } else {

                        // ======== Swap 失败 ========
                        console.error('Raydium swap error:', result.code, result.message);
                        setIsTransactionError(true);

                        // 如果你想模拟 err.code === 4001 => "User rejected"
                        // 可以在你 raydiumSwap 里返回 code=4001，或者做其它判断
                        if (result.code === 4001) {
                            setTransactionMessage("User rejected the transaction.");
                        } else {
                            setTransactionMessage(`Transaction failed: ${result.message}`);
                        }
                    }

                } catch (err) {
                    // 如果出现“未被 catch 的异常”
                    console.error(`Error transferring token on Solana:`, err);
                    setIsTransactionError(true);
                    // 参考你原先的 code：如果 code===4001 => "User rejected"
                    if (err?.code === 4001) {
                        setTransactionMessage("User rejected the transaction.");
                    } else {
                        setTransactionMessage(`Transaction failed: ${err.message}`);
                    }
                }
            }

            doRaydiumSwap();
        }
    }, [transactionTrigger]);

    // CLEAR SUCCESS/ERROR AFTER 5S
    useEffect(() => {
        console.log("finalMessage error", finalMessage)
        if (finalMessage) {
            const timer = setTimeout(() => {
                setTransactionMessage(null);

            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [finalMessage]);

    // to read local storage to store last connected wallet
    useEffect(() => {
        const lastWallet = localStorage.getItem('lastConnectedWallet');
        if (lastWallet === 'okx') {
            handleOKXWalletSolana();
        } else if (lastWallet === 'coinbase') {
            handleCoinbaseWalletSolana();
        }
    }, []);

    useEffect(() => {
        setFinalIsError(externalIsError)

    }, [externalIsError]);
    useEffect(() => {
        setFinalIsError(isTransactionError)

    }, [isTransactionError]);
    // -----------------------------------------
    // UTILITY
    // -----------------------------------------
    const handleConnectWalletClick = (e) => {
        e.stopPropagation();
        setShowPanel((prev) => !prev);
    };
    const closePanel = () => setShowPanel(false);
    const stopPropagation = (e) => e.stopPropagation();
    const handleOutsideClick = () => {
        if (showPanel) closePanel();
    };
    const handleMenuIconClick = (e) => {
        e.stopPropagation();
        setIsSideMenuOpen(true);
    };
    const handleOutsideClickSideMenu = () => {
        setIsSideMenuOpen(false);
    };
    const handleMenuItemClick = (item) => {
        setSelectedMenuItem(item);
        setIsSideMenuOpen(false);
    };
    const handleLoginWithEmail = () => {
        window.location.href = 'https://gateway.0max1.com';
    };

    function shortenAddress(address, startLength = 4, endLength = 4) {
        if (!address) return '';
        if (address.length <= startLength + endLength) {
            return address;
        }
        const start = address.slice(0, startLength);
        const end = address.slice(-endLength);
        return `${start}..${end}`;
    }

    // -----------------------------------------
    // OKX CONNECT & FETCH
    // -----------------------------------------
    const handleOKXWalletSolana = async () => {
        try {
            const provider = window.okxwallet?.solana;
            if (!provider) {
                alert('OKX Wallet (Solana) not detected. Please install or enable it.');
                return;
            }
            await provider.connect();
            const publicKey = provider.publicKey?.toString();
            if (!publicKey) {
                alert('Unable to retrieve Solana public key from OKX Wallet.');
                return;
            }

            setConnectedWalletType("okx");
            localStorage.setItem('lastConnectedWallet', 'okx');

            setWalletAddress(shortenAddress(publicKey));
            setConnectedPublicKey(publicKey);
            setOkxSolanaProvider(provider);
            setShowPanel(false);

            await fetchBalancesAndNotifyParent(provider, publicKey, onWalletConnected);
        } catch (error) {
            console.error('Error connecting to OKX Wallet (Solana):', error);
            alert('Failed to connect to OKX Wallet on Solana.');
        }
    };

    // -----------------------------------------
    // COINBASE CONNECT & FETCH
    // -----------------------------------------
    const handleCoinbaseWalletSolana = async () => {
        try {
            const provider = window.coinbaseSolana;
            if (!provider) {
                window.open('https://www.coinbase.com/wallet', '_blank');
                return;
            }
            await provider.connect();
            const publicKey = provider.publicKey?.toString();
            if (!publicKey) {
                alert('Unable to retrieve Solana public key from Coinbase Wallet.');
                return;
            }

            setConnectedWalletType("coinbase");
            localStorage.setItem('lastConnectedWallet', 'coinbase');

            setWalletAddress(shortenAddress(publicKey));
            setConnectedPublicKey(publicKey);
            setCoinbaseSolanaProvider(provider);
            setShowPanel(false);
            await fetchBalancesAndNotifyParent(provider, publicKey, onWalletConnected);
        } catch (error) {
            console.error('Error connecting to Coinbase Wallet (Solana):', error);
            alert('Failed to connect to Coinbase Wallet on Solana.');
        }
    };

    // -----------------------------------------
    // WALLETCONNECT
    // -----------------------------------------
    function handleWalletConnectSolana() {
        // If you want a direct QR code approach, e.g. open({ route, connector, etc. })
        // Right now it's empty to avoid changing logic.
        // Add your Web3Modal approach or custom logic here if needed.
    }


    // -----------------------------------------
    // TRANSFER LOGIC
    // -----------------------------------------


    const handleSolanaTransaction = async (C2C_USDI_RECEIVING_TOKEN_ACCOUNT = null) => {
        // 1) We need a connected public key
        if (!connectedPublicKey) {
            return;
        }
        // 2) Decide which provider to use based on wallet type
        let providerToUse = null;
        if (connectedWalletType === 'okx') {
            providerToUse = okxSolanaProvider;  // original
        } else if (connectedWalletType === 'coinbase') {
            providerToUse = coinbaseSolanaProvider; // newly added
        } else {
            // e.g. walletconnect not handled here or user not connected
            return;
        }

        if (!providerToUse || isNaN(amount) || Number(amount) <= 0) {
            return;
        }
        // 3) if its cash, add one more parameter from backend gcp
        if (selectedToken === 'CASH') {
            await transferSplToken(connectedPublicKey, providerToUse, 'USDI', C2C_USDI_RECEIVING_TOKEN_ACCOUNT);
        }
        // 3) If we are MINTing, pass selectedToken, if REDEEM, pass 'USDI'
        else if (transactionType === 'mint') {
            await transferSplToken(connectedPublicKey, providerToUse, selectedToken, null);
        } else if (transactionType === 'redeem') {
            await transferSplToken(connectedPublicKey, providerToUse, 'USDI', null);
        } else {
            console.log(`Transaction type "${transactionType}" not implemented.`);
        }
    };

    const transferSplToken = async (fromPublicKeyStr, provider, tokenType, C2C_USDI_RECEIVING_TOKEN_ACCOUNT) => {
        try {
            const connection = new Connection(RPC_URL, "confirmed");
            const fromPublicKey = new PublicKey(fromPublicKeyStr);

            let tokenMintAddress;
            let toTokenAccountPubkey;
            let readableToken;

            console.log("got here", C2C_USDI_RECEIVING_TOKEN_ACCOUNT, tokenType)

            // C2C_USDI_RECEIVING_TOKEN_ACCOUNT !== null means its cash
            if (C2C_USDI_RECEIVING_TOKEN_ACCOUNT !== null) {
                tokenMintAddress = USDI_MINT_ADDRESS;
                readableToken = "USDi";
                toTokenAccountPubkey = new PublicKey(C2C_USDI_RECEIVING_TOKEN_ACCOUNT);
            } else if (tokenType === 'USDT') {
                tokenMintAddress = USDT_MINT_ADDRESS;
                readableToken = "USDT";
                // squad vault 4dj....
                toTokenAccountPubkey = new PublicKey("3snmSNcrZ3CMdCBuLWgWNqmSyDa18EXk7AUCZ9AM1PG3");
            } else if (tokenType === 'USDC') {
                console.log("got here2")

                tokenMintAddress = USDC_MINT_ADDRESS;
                readableToken = "USDC";
                // squad vault 4dj....
                toTokenAccountPubkey = new PublicKey("7iQ1PmuqUD2uWVh6atLB8r42qE8BFk6uFUHX4PNzzcuY");
            } else if (tokenType === 'USDI') {
                tokenMintAddress = USDI_MINT_ADDRESS;
                readableToken = "USDi";
                // squad vault 4dj....
                toTokenAccountPubkey = new PublicKey("C4xyFYKJJQwD7sGNmi1av6f9XcPLZFZz25wXC6AH5bvc");
            } else {
                alert("Unknown token type. Canceling transaction.");
                return;
            }
            const latestBlockhash = await connection.getLatestBlockhash("confirmed");
            let signature

            // okx payer but can but coinbase as well maybe bug here
            const okxPayer = {
                publicKey: fromPublicKey,
                signTransaction: async (transaction) => {
                    return await provider.signTransaction(transaction);
                },
            };

            const mintPubkey = new PublicKey(tokenMintAddress);
            const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                okxPayer,
                mintPubkey,
                fromPublicKey
            );

            const amountInBaseUnits = Math.floor(Number(amount) * 1_000_000);
            if (isNaN(amountInBaseUnits) || amountInBaseUnits <= 0) {
                setIsTransactionError(true);
                setTransactionMessage(`Invalid ${readableToken} amount. Please provide a positive number.`);
                return;
            }

            const transferIx = createTransferInstruction(
                fromTokenAccount.address,
                toTokenAccountPubkey,
                fromPublicKey,
                amountInBaseUnits
            );
            const transaction = new Transaction().add(transferIx);
            transaction.feePayer = fromPublicKey;

            transaction.recentBlockhash = latestBlockhash.blockhash;
            const result = await provider.signAndSendTransaction(transaction);
            signature = result.signature

            console.log("signature here", signature)
            const explorerLink = `https://solscan.io/tx/${signature}`;
            setIsTransactionError(false);
            setTransactionMessage(
                `${transactionType} of ${amount} ${readableToken} successfully submitted.
                 <a href="${explorerLink}" target="_blank" rel="noreferrer">Check on Explorer</a>`
            );
            // callback- coule be deleted
            if (onSignature) {
                onSignature({
                    signature,
                    fromAddress: fromPublicKeyStr,
                    toAddress,
                    amount,
                    token: readableToken,
                });
            }
            console.log("fromPublicKeyStr", fromPublicKeyStr, amount, transactionTime, signature)

            // C2C_USDI_RECEIVING_TOKEN_ACCOUNT !== null -> c2c cash redeem flow
            if (C2C_USDI_RECEIVING_TOKEN_ACCOUNT !== null) {
                try {
                    const response = await fetch("https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/record_cash", {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify({
                            requester_wallet_address: fromPublicKeyStr,
                            redeem_amount: amount,
                            utc_time: transactionTime,
                            transfer_signature: signature
                        })
                    });
                    if (!response.ok) {
                        throw new Error(`Server responded with status ${response.status}`);
                    }
                    console.log("cash received!")
                    setCashRedeemTransactionID(response.cash_redeem_transaction_id)
                    setIsTransactionError(false);
                    setTransactionMessage("Cash received.");
                    const explorerLink = `https://solscan.io/tx/${signature}`;
                    setTransactionMessage(
                        `Cash received of ${amount} ${selectedToken.toUpperCase()} .
                         <a href="${explorerLink}" target="_blank" rel="noreferrer">Check on Explorer</a>`
                    );
                    // set a 临时的窗口让用户enter address to enter address
                    // 2) Prompt user for final address
                    // const walletAddr = prompt("Enter the client's receiving wallet address:");
                    // if (!walletAddr) {
                    //     // User canceled or left it blank
                    //     console.log("No address entered; skipping redeem request.");
                    //     return;
                    // }

                    // 3) Send redeem request
                    const redeemRes = await fetch("https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/redeem_request", {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify({
                            cash_redeem_transaction_id: cashRedeemTransactionID,
                            receiving_client_wallet_address: ""
                        })
                    });
                    if (!redeemRes.ok) {
                        throw new Error(`Redeem request failed with status ${redeemRes.status}`);
                    }

                    // 4) If redeem request also succeeds
                    setIsTransactionError(false);
                    setTransactionMessage("Redeem request successful!");

                } catch (error) {
                    console.error("Cash or redeem error:", error);
                    setIsTransactionError(true);
                    setTransactionMessage(`Error: ${error.message}`);
                }

            }
            // C2C_USDI_RECEIVING_TOKEN_ACCOUNT == null -> not c2c cash redeem flow
            else if (transactionType === 'mint') {
                triggerMintTransferToAddress(signature, fromPublicKeyStr, toAddress, amount);
            } else if (transactionType === 'redeem') {
                triggerRedeemTransferToAddress(signature, fromPublicKeyStr, toAddress, amount, tokenType);
            }
        } catch (err) {
            console.error(`Error transferring token on Solana:`, err);
            setIsTransactionError(true);
            if (err?.code === 4001) {
                setTransactionMessage("User rejected the transaction.");
            } else {
                setTransactionMessage(`Transaction failed: ${err.message}`);
            }
        }
    };


    const triggerMintTransferToAddress = async (signature, fromAddr, toAddr, amt) => {
        console.log("Mint Transfer triggered. Sending body:", signature, fromAddr, toAddr, amt);
        await sleep(60000);
        const requestBody = {
            "transaction_hash": String(signature),
            "from_address": String(fromAddr),
            "to_address": String(toAddr),
            "amount": String(amt)
        };

        fetch("https://usdi-api-256611876551.europe-west1.run.app/trigger-mint-transfer-to-address", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Server responded with status: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                console.log("API response:", data);
            })
            .catch((err) => {
                console.error("Error sending to server:", err);
            });
    };


    async function triggerRedeemTransferToAddress(signature, fromAddr, toAddr, amt, tokenType) {
        console.log("Manual redeem triggered. Sending body:", signature, fromAddr, toAddr, amt, tokenType);
        await sleep2(60000);

        const requestBody = {
            transaction_hash: String(signature),
            from_address: String(fromAddr),
            to_address: String(toAddr),
            amount: String(amt),
            token_type: String(tokenType)
        };

        fetch("https://usdi-api-256611876551.europe-west1.run.app/trigger-redeem-transfer-to-address", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Server responded with status: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                console.log("Redeem API response:", data);
            })
            .catch((err) => {
                console.error("Error calling redeem API:", err);
            });
    }


    // DISCONNECT
    const disconnectWallet = async () => {
        try {
            if (okxSolanaProvider && okxSolanaProvider.disconnect) {
                okxSolanaProvider.disconnect();
            }
            if (coinbaseSolanaProvider) {
                await window.coinbaseSolana.disconnect();
            }

            setConnectedPublicKey(null);
            setWalletAddress("Connect Wallet");
            setOkxSolanaProvider(null);
            setCoinbaseSolanaProvider(null); // reset coinbase
            setShowPanel(false);
            setConnectedWalletType(null);
            localStorage.removeItem('lastConnectedWallet');

            if (onWalletConnected) {
                onWalletConnected(null, 0, 0, 0);
            }
        } catch (err) {
            console.error("Error disconnecting wallet:", err);
        }
    };

    // if user clicks "WalletConnect"
    const handleWalletConnect = () => {
        handleWalletConnectSolana();
        setShowPanel(false);
    };

    const handleCoinbaseWallet = () => {
        handleCoinbaseWalletSolana();
    };

    return (
        <div className={styles.outerContainer} onClick={handleOutsideClick}>
            <img
                className={styles.vectorIcon}
                src={logo_svg}
                style={{width: '65px', height: '20px'}}
                alt="0MAX1"
            />

            {finalMessage ? (
                <div className={styles.transactionErrorBoxWapper}>
                    <div className={styles.transactionErrorBox}>
                        <div className={styles.transactionErrorContent}>
                            <img
                                src={finalIsError ? rejectImg : successImg}
                                alt={finalIsError ? "Rejected" : "Success"}
                                className={styles.rejectIcon}
                            />
                            <span
                                className={styles.errorMessage}
                                dangerouslySetInnerHTML={{__html: finalMessage}}
                            />
                        </div>
                        <img
                            className={styles.cancelBtn}
                            src={cancle_icon}
                            alt="x"
                            onClick={() => {
                                setTransactionMessage(null);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div className={styles.container}>
                    {isSideMenuOpen ? (
                        <SideMenu
                            isOpen={isSideMenuOpen}
                            selectedMenuItem={selectedMenuItem}
                            onMenuItemClick={handleMenuItemClick}
                            onOverlayClick={handleOutsideClickSideMenu}
                            stopPropagation={(e) => e.stopPropagation()}
                        />
                    ) : (
                        <div
                            className={styles.userIconContainer}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div
                                className={
                                    `${styles.userIcon} ` +
                                    (walletAddress === "Connect Wallet"
                                        ? styles.userIconNoHover
                                        : styles.userIconRedHover)
                                }
                                onClick={handleConnectWalletClick}
                                style={{
                                    background:
                                        walletAddress === "Connect Wallet" ? "white" : "#1E2830",
                                    color:
                                        walletAddress === "Connect Wallet" ? "black" : "white",
                                    borderRadius: "10px",
                                }}
                            >
                                {/* If user hasn't connected yet => no icon.
                                    If connected => show correct icon. */}
                                {walletAddress === "Connect Wallet" ? '' : (
                                    connectedWalletType === "okx" ? (
                                        <img
                                            style={{
                                                borderRadius: '50%',
                                                width: '18px',
                                                height: '18px',
                                                marginRight: '8px',
                                                marginLeft: '0px'
                                            }}
                                            src={okx_icon}
                                            alt="OKX"
                                        />
                                    ) : connectedWalletType === "coinbase" ? (
                                        <img
                                            style={{
                                                borderRadius: '50%',
                                                width: '18px',
                                                height: '18px',
                                                marginRight: '8px',
                                                marginLeft: '0px'
                                            }}
                                            src={coinbase_icon}
                                            alt="Coinbase"
                                        />
                                    ) : connectedWalletType === "walletconnect" ? (
                                        <img
                                            style={{
                                                borderRadius: '50%',
                                                width: '18px',
                                                height: '18px',
                                                marginRight: '8px',
                                                marginLeft: '0px'
                                            }}
                                            src={walletconnect_icon}
                                            alt="WalletConnect"
                                        />
                                    ) : null
                                )}
                                {walletAddress}
                                {walletAddress === "Connect Wallet" ? '' : (
                                    <img
                                        style={{
                                            borderRadius: '50%',
                                            width: '12px',
                                            height: '12px',
                                            marginRight: '0px',
                                            marginLeft: '8px',
                                            background: 'none'
                                        }}
                                        src={arrowDownImg}
                                        alt="arrow"
                                    />
                                )}
                            </div>

                            <img
                                className={styles.menu_mobile_icon}
                                src={menu_icon}
                                style={{width: '13.41', height: '15px'}}
                                alt="Menu"
                                onClick={handleMenuIconClick}
                            />

                            {showPanel && (
                                <div
                                    className={styles.overlay}
                                    onClick={handleOutsideClick}
                                ></div>
                            )}

                            <div
                                className={
                                    `${styles.sidePanel} ` +
                                    (showPanel
                                        ? (connectedPublicKey ? styles.openConnected : styles.openNotConnected)
                                        : '')
                                }
                                onClick={stopPropagation}
                            >
                                <div className={styles.connectHeader}>
                                    <h3>Connect</h3>
                                    <div
                                        className={styles.closeBtn}
                                        onClick={closePanel}
                                        aria-label="Close"
                                    >
                                        ×
                                    </div>
                                </div>

                                <div className={styles.lineWrapper}>
                                    <div className={styles.dividerLineFull}></div>
                                </div>

                                <div className={styles.walletOptions}>
                                    {/* OKX */}
                                    <div
                                        className={styles.connectOption}
                                        onClick={handleOKXWalletSolana}
                                    >
                                        <img className={styles.walletIcon} src={okx_icon} alt="OKX"/>
                                        <p>OKX Wallet</p>
                                    </div>

                                    {/* WalletConnect */}
                                    <div
                                        className={styles.connectOption}
                                        onClick={handleWalletConnect}
                                    >
                                        <img
                                            className={styles.walletIcon}
                                            src={walletconnect_icon}
                                            alt="WalletConnect"
                                        />
                                        <p>WalletConnect</p>
                                    </div>

                                    {/* Coinbase */}
                                    <div
                                        className={styles.connectOption}
                                        onClick={handleCoinbaseWalletSolana}
                                    >
                                        <img className={styles.walletIcon} src={coinbase_icon} alt="Coinbase"/>
                                        <p>Coinbase Wallet</p>
                                    </div>

                                    {connectedPublicKey && (
                                        <div className={styles.lineWrapper}>
                                            <div className={styles.dividerLineFull}></div>
                                        </div>
                                    )}

                                    {connectedPublicKey && (
                                        <div
                                            className={styles.connectOption}
                                            onClick={disconnectWallet}
                                            style={{paddingTop: "13px", paddingBottom: '13px'}}
                                        >
                                            <img className={styles.walletIcon} src={disconnectImg} alt="disconnect"/>
                                            <p style={{color: 'red'}}>Disconnect</p>
                                        </div>
                                    )}

                                    <div className={styles.lineWrapper}>
                                        <div className={styles.dividerLine}></div>
                                        <div className={styles.or}>Or</div>
                                        <div className={styles.dividerLine}></div>
                                    </div>

                                    {/* Email */}
                                    <div
                                        className={styles.connectOption}
                                        onClick={handleLoginWithEmail}
                                    >
                                        <img
                                            style={{background: '#1C1C1C', marginRight: '17px'}}
                                            src={email_svg}
                                            alt="email"
                                        />
                                        <p>Log in with Email</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ConnectWalletHeader;
