// TransactionMain.jsx
import React, {useEffect, useState} from 'react';
import styles from './TransactionHistory.module.css';

import logo_svg from './static_files/logo-nobackground.svg';
import transImg from './static_files/My Assets.svg';
import assetsImg from './static_files/Transactions.svg';
import inviteImg from './static_files/Invite-friends.svg';
import ConnectWalletHeader from "./header/ConnectWalletHeader";
import uIconSvg from "./static_files/USDi.png";
import arrowUpSvg from './static_files/Vector Up.svg';
import arrowDownSvg from './static_files/Vector Down.svg';
import expandSvg from "./static_files/arrow_down.svg";

const TransactionHistory = () => {
    const [confirmedAmount, setConfirmedAmount] = useState(0);
    const [selectedTab, setSelectedTab] = useState('mint'); // "mint" | "redeem" | "swap"
    const [txTrigger, setTxTrigger] = useState(0);

    // New states for wallet data
    const [walletAddress, setWalletAddress] = useState("");

    const [usdtBalance, setUsdtBalance] = useState(0);
    const [usdcBalance, setUsdcBalance] = useState(0);
    const [usdiBalance, setUsdiBalance] = useState(0);
    const [selectedToken, setSelectedToken] = useState("USDC");
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        // 这里模拟请求返回的数据，你可以改成真实 fetch
        const mockData = [
            {
                id: 1,
                arrowDirection: 'up',   // "up" 表示箭头朝上, "down" 表示箭头朝下
                type: 'Redeem',         // 或者 "Mint"
                amount: 0.01,           // 从请求来
                address: 'Aree...sedT', // 从请求来
                time: '02:08 PM',       // 从请求来
                status: 'Completed',    // "Completed"/"Pending" 等从请求来
            },
            {
                id: 2,
                arrowDirection: 'down',
                type: 'Mint',
                amount: 0.01,
                address: 'Aree...sedT',
                time: '02:08 PM',
                status: 'Pending',
            },
            // ...更多
        ];
        setTransactions(mockData);
    }, []);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#04070D',
                height: '100vh',
                width: '100vw',
            }}
        >
            {/* LEFT PANEL */}
            <div className={styles.leftPanel}>
                <div className="topLeftImageWrapper">
                    <a
                        style={{backgroundColor: '#04070D'}}
                        href="https://0max1.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            style={{backgroundColor: '#04070D'}}
                            className={styles.vectorIcon}
                            alt=""
                            src={logo_svg}
                        />
                    </a>
                </div>
                <div
                    className={styles.leftMenu}
                    style={{width: '188px'}}
                >
                    <ul
                        className={styles.leftOptionUl}
                        style={{backgroundColor: '#04070D'}}
                    >
                        <li
                            className={styles.leftOption}
                            onClick={() => window.location.href = "/"}
                        >
                            <img
                                style={{marginRight: "12px"}}
                                src={transImg}
                                alt=""
                            />
                            My Assets
                        </li>
                        <li
                            className={styles.leftOption}
                            onClick={() => window.location.href = "/transaction"}
                        >
                            <img
                                style={{marginRight: "12px", background: "#32363D"}}
                                src={assetsImg}
                                alt=""
                            />
                            Transactions
                        </li>
                        <li className={styles.leftOption}>
                            <img
                                style={{marginRight: '12px'}}
                                src={inviteImg}
                                alt=""
                            />
                            Invite Friends
                        </li>
                    </ul>
                </div>
            </div>

            {/*main panel*/}
            <div className={styles.RightPanelOuterContainer}>

                <div className={styles.rightPanel} style={{display: "flex", flexDirection: "column"}}>
                    <ConnectWalletHeader
                        amount={confirmedAmount}
                        transactionType={selectedTab} // "mint" or "redeem" or "swap"
                        transactionTrigger={txTrigger}
                        toAddress="4dJ5ATt3BbJPrbYCZGLAXndi5DPaZZY9j1Sp8Hdh4ApH"
                        selectedToken={selectedToken}
                        onWalletConnected={(publicKey, usdtLeft, usdcLeft, usdiLeft) => {
                            setWalletAddress(publicKey);
                            setUsdtBalance(usdtLeft);
                            setUsdcBalance(usdcLeft);
                            setUsdiBalance(usdiLeft);
                        }}
                        onSignature={(signature) => {
                            // no-op for now
                        }}
                    />

                    {/*Transaction tab*/}
                    <div className={styles.transactionContainer}>
                        <div className={styles.transactionsTitle}>Transactions</div>
                        <div className={styles.transactionsToday}>Today</div>

                        <div className={styles.transactionList}>
                            {transactions.map(tx => {
                                // 根据 arrowDirection 选用对应的箭头
                                const arrowIcon = tx.arrowDirection === 'up' ? arrowUpSvg : arrowDownSvg;

                                return (
                                    <div className={styles.transactionRow}>
                                        <img className={styles.directionArrow} src={arrowIcon}/>
                                        <span className={styles.txType}>{tx.type}</span>
                                        <img className={styles.uIcon} src={uIconSvg}/>
                                        <span className={styles.amount}>{tx.amount}</span>
                                        <span className={styles.usdi}>USDi</span>
                                        <span className={styles.to}>To:</span>
                                        <span className={styles.toAddress}>{tx.address} </span>
                                        <span className={styles.time}>{tx.time}</span>
                                        <span
                                            className={tx.status === 'Completed' ? styles.statusCompleted : styles.statusPending}>
                                        {tx.status}
                                      </span>
                                        <img className={styles.expandIcon} src={expandSvg}/>
                                    </div>

                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionHistory;
