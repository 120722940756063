// components/Table/RawBlockchainWithTabs.js

import React, {useEffect, useState} from 'react';
import {digitFormat} from '../utils';
import '../tables.css';


// ！！！！！note that it won't display the transactions with no from address and to address
// because in solscan, the transaction wasn't being displayed as well
// because some transaction detected with USDi in it was not an actual transaction that transfer USDi
const RawBlockchainWithTabs = ({
    selectedChain = 'eth'
}) => {
    const [ethereumData, setEthereumData] = useState([]);
    const [solanaData, setSolanaData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // 1) Fetch Ethereum data
    const fetchEthereumData = async () => {
        try {
            setLoading(true);
            const response = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/raw_blockchain_transactions');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();

            const ethereumAddress = '0xD458Ef69a04C37812ae1a7d3BbBC0DfB8f0c0C46';
            const filteredEth = result.filter(
                (item) => item.token_smart_contract_address === ethereumAddress
            );
            setEthereumData(filteredEth);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // 2) Fetch Solana data
    const fetchSolanaData = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                'https://usdi-raw-transactions-256611876551.europe-west1.run.app/get-solana-transactions'
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();

            setSolanaData(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // 3) On component mount, fetch Ethereum & process events
    useEffect(() => {
        // 初次挂载时先获取 Ethereum 数据
        fetchEthereumData();

        // 同时请求后端处理 (Ethereum) 事件
        const requestRefreshData = async () => {
            try {
                // 处理 ETH
                const response = await fetch(
                    'https://usdi-raw-transactions-256611876551.europe-west1.run.app/process-events'
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();

                // 处理完成后再刷新 Ethereum data
                await fetchEthereumData();

                // 处理 Solana
                const response2 = await fetch(
                    'https://usdi-raw-transactions-256611876551.europe-west1.run.app/process-solana-events'
                );
                if (!response2.ok) {
                    throw new Error('Network response was not ok');
                }
                const result2 = await response2.json();

                // 再刷新 Solana data
                await fetchSolanaData();
            } catch (error) {
                setError(error);
            }
        };

        requestRefreshData();
    }, []);

    // 4) 当 selectedChain 发生变化时，再抓取对应数据
    useEffect(() => {
        if (selectedChain === 'eth') {
            fetchEthereumData();
        } else if (selectedChain === 'solana') {
            fetchSolanaData();
        }
    }, [selectedChain]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    // Reusable table component
    const BlockchainTable = ({tableData, isEthereum}) => {
        // 1) 按 blockno (降序) 排序
        const sortedData = [...tableData].sort((a, b) => {
            return Number(b.blockno) - Number(a.blockno);
        });

        // 2) 过滤掉 from_address 和 to_address 同时为空的行
        const filteredData = sortedData.filter(
            (row) => !(row.from_address === '' && row.to_address === '')
        );

        return (
            <div className="table-responsive noto">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                        <tr>
                            <th>blockno</th>
                            <th>date_time_utc</th>
                            <th>from_address</th>
                            <th>method</th>
                            <th>quantity</th>
                            <th>to_address</th>
                            <th>transaction_hash</th>
                            <th>token_smart_contract_address</th>
                            <th>unix_timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((row, index) => (
                            <tr key={index}>
                                <td>{digitFormat(row.blockno)}</td>
                                <td>{row.date_time_utc}</td>
                                <td>{row.from_address}</td>
                                <td>{row.method}</td>
                                <td>{digitFormat(row.quantity)}</td>
                                <td>{row.to_address}</td>

                                {/* Ethereum 与 Solana transaction_hash 的字段名不太一样 */}
                                <td>{isEthereum ? row.transaction_hash : row.Transaction_hash}</td>
                                <td>{row.token_smart_contract_address}</td>
                                <td>{digitFormat(row.unix_timestamp)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    // 根据 selectedChain 来决定显示哪一个表格
    return (
        <div>
            <div className="mt-3">
                {selectedChain === 'eth' && (
                    <BlockchainTable tableData={ethereumData} isEthereum={true} />
                )}
                {selectedChain === 'solana' && (
                    <BlockchainTable tableData={solanaData} isEthereum={false} />
                )}
            </div>
        </div>
    );
};

export default RawBlockchainWithTabs;
