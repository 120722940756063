import React from 'react';
import styles from './MobileMenu.module.css';

import inviteImg from '../static_files/Invite-friends.svg';
import inviteImgGreen from '../static_files/Invite-friends green.svg';
import transactionImg from '../static_files/Transactions.svg';
import transactionImgGreen from '../static_files/Transactions green.svg';
import myAssetsImg from '../static_files/My Assets.svg';
import myAssetsImgGreen from "../static_files/My Assets Green.svg";

function SideMenu({
  isOpen,
  selectedMenuItem,
  onMenuItemClick,
  onOverlayClick,
  stopPropagation
}) {
  if (!isOpen) {
    return null; // if closed, return nothing
  }

  return (
    <>
      {/* Overlay that covers the entire screen */}
      <div
        className={styles.sideMenuOverlay}
        onClick={onOverlayClick}
      />

      {/* Side Menu container */}
      <div
        className={`${styles.sideMenuContainer} ${isOpen ? styles.open : ''}`}
        onClick={stopPropagation}
      >
        {/* My Assets */}
        <div
          className={`${styles.menuItem} ${
            selectedMenuItem === 'myAssets' ? styles.selected : ''
          }`}
          onClick={() => onMenuItemClick('myAssets')}
        >
          <img
            src={selectedMenuItem === 'myAssets' ? myAssetsImgGreen : myAssetsImg}
            alt="My Assets"
          />
          My Assets
        </div>

        {/* Transactions */}
        <div
          className={`${styles.menuItem} ${
            selectedMenuItem === 'transactions' ? styles.selected : ''
          }`}
          onClick={() => onMenuItemClick('transactions')}
        >
          <img
            src={
              selectedMenuItem === 'transactions' ? transactionImgGreen : transactionImg
            }
            alt="Transactions"
          />
          Transactions
        </div>

        {/* Invite Friends */}
        <div
          className={`${styles.menuItem} ${
            selectedMenuItem === 'inviteFriends' ? styles.selected : ''
          }`}
          onClick={() => onMenuItemClick('inviteFriends')}
        >
          <img
            src={selectedMenuItem === 'inviteFriends' ? inviteImgGreen : inviteImg}
            alt="Invite Friends"
          />
          Invite Friends
        </div>
      </div>
    </>
  );
}

export default SideMenu;
