// GatewayTokenVerifier.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import loadingImg from '../src/tables/static_files/loading 1.png'
import './App.css'
const GatewayTokenVerifier = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { verifyGatewayToken } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (!token) {
      navigate('/');
      return;
    }

    (async () => {
      const isValid = await verifyGatewayToken(token);
      if (!isValid) {
        navigate('/');
      } else {
        setLoading(false);
      }
    })();
  }, [location, navigate, verifyGatewayToken]);

 if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <img className="LoadingImage" src={loadingImg} alt="Loading"/>
      </div>
    );
  }

  return children;
};

export default GatewayTokenVerifier;
