import React, {createContext, useContext, useState} from 'react';
// import {fetchJWT} from "./tables/linechart/login";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(
        () => JSON.parse(localStorage.getItem('isAuthenticated')) || false
    );
    const [isGatewayAuthenticated, setIsGatewayAuthenticated] = useState(false);
    const [gatewayUserData, setGatewayUserData] = useState(null);
    const adminData = {
        bsc_receive_address: null,
        email: "",
        ethereum_receive_address: null,
        hash_code: null,
        solana_receive_address: null,
        tron_receive_address: null,
        wire_info: null
    };

// Then call:
    const login = (username, password) => {
        // Admin login flow
        if (username === 'arb@0max1.com' && password === 'Bitcoin110') {
            setIsAuthenticated(true);
            localStorage.setItem('isAuthenticated', JSON.stringify(true));
            localStorage.setItem('tokenExpiry', Date.now() + 60 * 1000);

            setGatewayUserData(adminData || null);

            return true;
        }
        return false;
    };

//
    const logout = async () => {
        const email = gatewayUserData?.email;
        if (isGatewayAuthenticated) {
            try {
                const response = await fetch('https://max1-funding-arb.uc.r.appspot.com/logout', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({email})
                });

                if (!response.ok) {
                    const result = await response.json();
                    console.error('Failed to logout:', result.msg);
                } else {
                    console.log('Logout successful');
                    // Redirect first, before altering states
                    window.location.href = "https://gateway.0max1.com/";
                }
            } catch (error) {
                console.error('Error while logging out:', error);
            }
        } else {
            // Admin user: Clear state and storage, no redirect
            setIsAuthenticated(false);
            setIsGatewayAuthenticated(false);
            setGatewayUserData(null);
            localStorage.removeItem('isAuthenticated');
            setIsAuthenticated(false);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refresh_token');
            // No redirect
        }

    };


    const verifyGatewayToken = async (token) => {
        try {
            const response = await fetch('https://max1-funding-arb.uc.r.appspot.com/verify_token', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({token})
            });

            if (!response.ok) {
                // HTTP error, treat as invalid
                setIsGatewayAuthenticated(false);
                setGatewayUserData(null);
                return false;
            }

            const result = await response.json();

            if (result.success) {
                // Gateway verification successful
                setIsGatewayAuthenticated(true);
                setGatewayUserData(result.data || null);
                // console.log("data, here", result.data)
                return true;
            } else {
                // Gateway verification failed
                setIsGatewayAuthenticated(false);
                setGatewayUserData(null);
                return false;
            }
        } catch (error) {
            console.error('Error verifying gateway token:', error);
            setIsGatewayAuthenticated(false);
            setGatewayUserData(null);
            return false;
        }
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            isGatewayAuthenticated,
            gatewayUserData,
            login,
            logout,
            verifyGatewayToken
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
