
export function getLineGradient(chart) {

  const {
    ctx,
    chartArea: { top, bottom, left, right },
  } = chart;
  const gradientSegment = ctx.createLinearGradient(0, bottom, 0, top);
  if (!chart) {
    gradientSegment.addColorStop(0, "white");
    gradientSegment.addColorStop(1, "white");
  } else {
    gradientSegment.addColorStop(0, "rgba(84, 133, 255, 0.15)");
    gradientSegment.addColorStop(0.4, "rgba(0, 228, 254, 0.3)");
    gradientSegment.addColorStop(1, "rgba(0, 255, 102, 0.6)");
  }
  return gradientSegment;
}

export function getLineGradient2(chart) {

  const {
    ctx,
    chartArea: { top, bottom, left, right },
  } = chart;
  const gradientSegment = ctx.createLinearGradient(0, bottom, 0, top);
  if (!chart) {
    gradientSegment.addColorStop(0, "white");
    gradientSegment.addColorStop(1, "white");
  } else {
    gradientSegment.addColorStop(0, "rgba(108,227,196,0.11)");
    gradientSegment.addColorStop(0.5, "rgba(108,227,196,0.43)");
    gradientSegment.addColorStop(1, "rgba(108,227,196,0.47)");
  }
  return gradientSegment;
}
// Line chart Border Gradient
export function getLineBorderGradient(chart) {
  const {
    ctx,
    chartArea: { top, bottom, left, right },
  } = chart;
  const gradientBorder = ctx.createLinearGradient(left, 0, right, 0);
  gradientBorder.addColorStop(0, "rgba(63, 99, 187, 1)");
  gradientBorder.addColorStop(0.5, " rgba(0, 199, 222, 1)");
  gradientBorder.addColorStop(1, "rgba(1, 246, 99, 1)");
  return gradientBorder;
}

export function getLineBorderGradient2(chart) {
  const {
    ctx,
    chartArea: { top, bottom, left, right },
  } = chart;
  const gradientBorder = ctx.createLinearGradient(left, 0, right, 0);
  gradientBorder.addColorStop(0, "#6CE3C4");
  gradientBorder.addColorStop(1, "#6CE3C4");
  return gradientBorder;
}

