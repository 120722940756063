import {Connection, PublicKey} from "@solana/web3.js";
import {getOrCreateAssociatedTokenAccount, TOKEN_PROGRAM_ID} from "@solana/spl-token";


 const RPC_URL = "https://solitary-boldest-thunder.solana-mainnet.quiknode.pro/7e14722814e9fc29ad81532c2da66c2ffbe3781f"
    // SPL mint addresses (mainnet)
    const USDT_MINT_ADDRESS = 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB';
    const USDC_MINT_ADDRESS = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v';
    const USDI_MINT_ADDRESS = 'CXbKtuMVWc2LkedJjATZDNwaPSN6vHsuBGqYHUC4BN3B';

    export const fetchBalancesAndNotifyParent = async (provider, publicKey,onWalletConnected) => {
        try {
            const connection = new Connection(RPC_URL, "confirmed");
            const userPubkey = new PublicKey(publicKey);

            const payer = {
                publicKey: userPubkey,
                signTransaction: async (tx) => {
                    return await provider.signTransaction(tx);
                }
            };

            let usdcBalance = 0;
            let usdtBalance = 0;
            let usdiBalance = 0;

            // USDC
            try {
                const usdcMintPubkey = new PublicKey(USDC_MINT_ADDRESS);
                const usdcAccount = await getOrCreateAssociatedTokenAccount(
                    connection,
                    payer,
                    usdcMintPubkey,
                    userPubkey
                );
                usdcBalance = Number(usdcAccount.amount) / 1_000_000;
            } catch (err) {
                console.error("Error fetching USDC balance:", err);
            }

            // USDT
            try {
                const usdtMintPubkey = new PublicKey(USDT_MINT_ADDRESS);
                const usdtAccount = await getOrCreateAssociatedTokenAccount(
                    connection,
                    payer,
                    usdtMintPubkey,
                    userPubkey
                );
                usdtBalance = Number(usdtAccount.amount) / 1_000_000;
            } catch (err) {
                console.error("Error fetching USDT balance:", err);
            }

            // USDi
            try {
                const usdiMintPubkey = new PublicKey(USDI_MINT_ADDRESS);
                const usdiAccount = await getOrCreateAssociatedTokenAccount(
                    connection,
                    payer,
                    usdiMintPubkey,
                    userPubkey
                );
                usdiBalance = Number(usdiAccount.amount) / 1_000_000;
            } catch (err) {
                console.error("Error fetching USDi balance:", err);
            }

            if (onWalletConnected) {
                onWalletConnected(publicKey, usdtBalance, usdcBalance, usdiBalance);
            }
        } catch (err) {
            console.error("Error fetching balances:", err);
        }
    };

    export const getTokenAccountFromWalletAddress = async (walletToQuery = "Arqe1a33PSbXCHaqKVeKW16QnJPAMM1Bhmfj6UANvX8Q") => {
        const connection = new Connection(RPC_URL, "confirmed");
        const mintAddress = "CXbKtuMVWc2LkedJjATZDNwaPSN6vHsuBGqYHUC4BN3B";
        const filters = [
            {
                dataSize: 165,
            },
            {
                memcmp: {
                    offset: 32,       // wallet owner is at offset 32
                    bytes: walletToQuery,
                },
            },
            {
                memcmp: {
                    offset: 0,        // mint is at offset 0
                    bytes: mintAddress,
                },
            },
        ];

        // Fetch parsed accounts owned by the SPL Token Program with these filters
        const accounts = await connection.getParsedProgramAccounts(
            TOKEN_PROGRAM_ID,
            {filters}
        );

        if (!accounts.length) {
            console.log(`No token account found for wallet ${walletToQuery} and mint ${mintAddress}`);
            return null;
        }

        const tokenAccountAddress = accounts[0].pubkey.toBase58();
        console.log(`Found token account: ${tokenAccountAddress}`);
        return tokenAccountAddress;
    };

    export function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    export function sleep2(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
