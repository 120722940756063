import {ENDPOINT_BASE_URL} from "../../configs/config";

export const fetchJWT = async () => {
    try {
        const response = await fetch(`${ENDPOINT_BASE_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: 'admin',
                password: 'admin',
            }),
        });

        if (!response.ok) {
            throw new Error('Login failed');
        }

        const data = await response.json();

        return data.access_token;
    } catch (error) {
        console.error('Error during login:', error);
        return null; 
    }
};
