import React, { useEffect, useState } from 'react';
import { digitFormat, moneyFormat } from "../utils";

const OverallUSDIPayout = ({ selectedChain, onStatusChange }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [status, setStatus] = useState("calculated");
    const [distributedData, setDistributedData] = useState(null);
    const [distributedError, setDistributedError] = useState(null);
    const [distributedLoading, setDistributedLoading] = useState(true);
    const [isDistributed, setIsDistributed] = useState(false);

    // 1) Decide which URLs to use based on selectedChain
    const triggerPayoutCalculatorUrl = selectedChain === 'solana'
        ? 'https://payout-calculator-256611876551.europe-west1.run.app/trigger-payout-calculator_solana'
        : 'https://payout-calculator-256611876551.europe-west1.run.app/trigger-payout-calculator';

    const overallUsdIPayoutUrl = selectedChain === 'solana'
        ? 'https://usdi-api-256611876551.europe-west1.run.app/overall_usdi_payout_solana'
        : 'https://usdi-api-256611876551.europe-west1.run.app/overall_usdi_payout';

    // 2) Fetch "calculated_data" when component mounts OR selectedChain changes
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(triggerPayoutCalculatorUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ update: false }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                setData(result.calculated_data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [triggerPayoutCalculatorUrl]);

    // 3) Fetch "overall_usdi_payout" distribution data when component mounts OR selectedChain changes
    useEffect(() => {
        const fetchDistributedData = async () => {
            setDistributedLoading(true);
            setDistributedError(null);
            try {
                const response = await fetch(overallUsdIPayoutUrl);

                if (!response.ok) {
                    throw new Error('Failed to fetch distributed data');
                }

                const result = await response.json();
                setDistributedData(result);
            } catch (err) {
                setDistributedError(err);
            } finally {
                setDistributedLoading(false);
            }
        };

        fetchDistributedData();
    }, [overallUsdIPayoutUrl]);

    // 4) Handle manual "Update" button – re‐fetch both endpoints for fresh data
    const handleUpdate = async () => {
        setUpdating(true);
        setError(null);
        try {
            const response = await fetch(triggerPayoutCalculatorUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ update: false }),
            });

            if (!response.ok) throw new Error('Failed to trigger distribution calculation');

            const result = await response.json();
            setData(result.calculated_data);

            const distResponse = await fetch(overallUsdIPayoutUrl);
            if (!distResponse.ok) throw new Error('Failed to fetch updated distributed data');

            const distResult = await distResponse.json();
            setDistributedData(distResult);
        } catch (err) {
            setError(err);
        } finally {
            setUpdating(false);
        }
    };

    // 5) When user selects "Distributed", we confirm & attempt the actual distribution, then update
    const handleStatusChange = async (newStatus) => {
        const isConfirmed = window.confirm(`Are you sure you want to change the status to ${newStatus}?`);
        if (isConfirmed) {
            setStatus(newStatus);
            onStatusChange(newStatus);

            try {
                const response = await fetch(triggerPayoutCalculatorUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ update: true }),
                });

                if (!response.ok) throw new Error('Failed to trigger distribution calculation');

                // If needed, we can setData here or parse any response
                const updated = await response.json();
                setData(updated);

                // Then fetch fresh data to reflect changes
                await handleUpdate();
            } catch (err) {
                setError(err);
            }

            // After distribution done, revert the local status to "calculated" or keep it as is
            setStatus("calculated");
        }
    };

    // 6) Render
    if (loading || distributedLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (distributedError) return <div>Error: {distributedError.message}</div>;

    return (
        <>
            <div className='button_wrapper'>
                <h3>Overall USDI Payout</h3>
                <button
                    className={`button ${updating ? 'updating' : ''}`}
                    onClick={handleUpdate}
                    disabled={updating}
                >
                    {updating ? 'Updating' : 'Update'}
                </button>
                {isDistributed && <span>Distributed</span>} {/* If you want to show "Distributed" after completion */}
            </div>

            {data && distributedData && (
                <div className="table-responsive noto">
                    <table className="table table-striped table-bordered mt-3">
                        <thead className="thead-dark">
                            <tr>
                                <th>Status</th>
                                <th>Block No</th>
                                <th>Total Assets Value</th>
                                <th>USDI Locked</th>
                                <th>USDI In Circulation</th>
                                <th>Distribution ID</th>
                                <th>Available Distribution Value</th>
                                <th>Distribution Value</th>
                                <th>Distribution Amount Fees</th>
                                <th>Final Distribution Amount After Management Fees</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Current "calculated_data" row */}
                            <tr>
                                <td>
                                    <select
                                        value={status}
                                        onChange={(e) => handleStatusChange(e.target.value)}
                                        className="status_dropdown"
                                    >
                                        <option value="calculated">Calculated</option>
                                        <option value="distributed">Distributed</option>
                                    </select>
                                </td>
                                <td>{digitFormat(data.current_block_number)}</td>
                                <td>{moneyFormat(data.total_assets_value)}</td>
                                <td>{digitFormat(data.usdi_locked)}</td>
                                <td>{digitFormat(data.usdi_in_circulation)}</td>
                                <td>NA</td>
                                <td>{moneyFormat(data.available_distribution_value)}</td>
                                <td>{moneyFormat(data.distribution_value)}</td>
                                <td>{moneyFormat(data.distribution_amount_of_fees)}</td>
                                <td>{moneyFormat(data.final_distribution_amount)}</td>
                            </tr>

                            {/* Historical distribution rows */}
                            {distributedData
                                .slice()
                                .sort((a, b) => b.block_no - a.block_no)
                                .map((row, index) => (
                                    <tr key={index}>
                                        <td>Distributed</td>
                                        <td>{digitFormat(row.block_no)}</td>
                                        <td>{moneyFormat(row.total_assets_value)}</td>
                                        <td>{digitFormat(row.usdi_locked)}</td>
                                        <td>{digitFormat(row.usdi_in_circulation)}</td>
                                        <td>{distributedData.length - index}</td>
                                        <td>{moneyFormat(row.available_distribution_value)}</td>
                                        <td>{moneyFormat(row.distribution_value)}</td>
                                        <td>{moneyFormat(row.distribution_amount_fees)}</td>
                                        <td>{moneyFormat(row.final_distribution_amount_after_management_fees)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default OverallUSDIPayout;
