import React, {useEffect, useState} from 'react';
import {digitFormat, moneyFormat, moneyFormat4decimals} from '../utils';
import './USDIStats.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';

const HolderInfoTable = ({parentStatus, selectedChain}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [distributing, setDistribute] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [updatingDWS, setUpdatingDWS] = useState(false); // State for updating DWS
    const [calculating, setCalculating] = useState(false);
    const [error, setError] = useState(null);
    const [addressToUserIdMap, setAddressToUserIdMap] = useState({});
    const [historicalData, setHistoricalData] = useState({});
    const [hoveredAddress, setHoveredAddress] = useState(null);

    /**
     * 1) Define the base endpoints for each chain.
     *    If selectedChain === 'solana', we add `_solana`.
     */
    const API_BASE_USDI = 'https://usdi-api-jlukzyz7wa-ew.a.run.app';
    const API_BASE_DIST = 'https://distribution-calculator-256611876551.europe-west1.run.app';

    // For fetching holder info
    const holdersInfoUrl = selectedChain === 'solana'
        ? `${API_BASE_USDI}/usdi_holders_info_table_solana`
        : `${API_BASE_USDI}/usdi_holders_info_table`;

    // For raw blockchain transactions
    const rawBlockchainTxUrl = selectedChain === 'solana'
        ? `${API_BASE_USDI}/raw_blockchain_transactions_solana`
        : `${API_BASE_USDI}/raw_blockchain_transactions`;

    // For user balance historical data
    // e.g. .../get_user_balance_solana?user_address=...
    const getUserBalanceEndpoint = selectedChain === 'solana'
        ? `${API_BASE_DIST}/get_user_balance_solana`
        : `${API_BASE_DIST}/get_user_balance`;

    // For admin-trigger (handleUpdate)
    const adminTriggerEndpoint = selectedChain === 'solana'
        ? `${API_BASE_DIST}/admin-trigger_solana`
        : `${API_BASE_DIST}/admin-trigger`;

    // For user_balance_blocks_update (handleUpdateDWS)
    const userBalanceBlocksUpdateEndpoint = selectedChain === 'solana'
        ? `${API_BASE_DIST}/user_balance_blocks_update_solana`
        : `${API_BASE_DIST}/user_balance_blocks_update`;

    // For distribution
    const distributeUsdiEndpoint = selectedChain === 'solana'
        ? `${API_BASE_DIST}/distribute_usdi_solana`
        : `${API_BASE_DIST}/distribute_usdi`;

    // For calculation-trigger
    const calculationTriggerEndpoint = selectedChain === 'solana'
        ? `${API_BASE_DIST}/calculation-trigger_solana`
        : `${API_BASE_DIST}/calculation-trigger`;


    /**
     * 2) fetchData() -> fetches the holder info table + raw blockchain transactions.
     *    Called in useEffect (below) whenever selectedChain changes.
     */
    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(holdersInfoUrl);
            if (!response.ok) throw new Error('Network response was not ok');
            const result = await response.json();
            console.log('Fetched holder info:', result);
            setData(result);

            const rawResponse = await fetch(rawBlockchainTxUrl);
            if (!rawResponse.ok) throw new Error('Network response was not ok');
            const rawResult = await rawResponse.json();


            // Build a map of address => numeric userId
            const addressMap = {};
            let userId = 1;
            rawResult.forEach((transaction) => {
                const normalizedAddress = transaction.to_address.toLowerCase().slice(0, 42);
                if (!addressMap[normalizedAddress]) {
                    addressMap[normalizedAddress] = userId++;
                }
            });

            setAddressToUserIdMap(addressMap);

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    /**
     * 3) fetchHistoricalData() -> fetch user historical data from the distribution service.
     *    This is done on hover, so that you can see each user’s block-by-block breakdown.
     */
    const fetchHistoricalData = async (address) => {
        try {
            const url = `${getUserBalanceEndpoint}?user_address=${address}`;
            const response = await fetch(url);
            if (!response.ok) throw new Error('Failed to fetch historical data');
            const result = await response.json();
            console.log("RESULT HERE", result);

            setHistoricalData((prevData) => ({
                ...prevData,
                [address]: result.data,
            }));
        } catch (error) {
            console.error('Error fetching historical data:', error);
        }
    };

    /**
     * 4) On mount and whenever selectedChain changes, re‐fetch data and do the handleUpdate() logic.
     */
    useEffect(() => {
        fetchData();
        handleUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // ^ Add selectedChain as a dependency so that reloading occurs when chain changes.
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChain]);
    /**
     * 5) handleUpdate() -> triggers the distribution calculation on the backend,
     *    then re‐fetches the data to refresh the table.
     */
    const handleUpdate = async () => {
        try {
            setUpdating(true);
            const response = await fetch(adminTriggerEndpoint, {
                method: 'POST',
            });

            if (response.ok) {
                fetchData();
            } else {
                throw new Error('Failed to trigger distribution calculation');
            }
        } catch (err) {
            setError(err);
        } finally {
            setUpdating(false);
        }
    };

    /**
     * 6) handleUpdateDWS() -> triggers the user_balance_blocks_update
     *    so you can specify a range of blocks to recalc data.
     */
    const handleUpdateDWS = async () => {
        let start_block = prompt('Enter Start Block Number:');
        let end_block = prompt('Enter End Block Number:');

        // Remove commas and validate
        start_block = start_block ? start_block.replace(/,/g, '') : '';
        end_block = end_block ? end_block.replace(/,/g, '') : '';

        if (!start_block || !end_block) {
            alert('Both start block and end block numbers are required!');
            return;
        }

        try {
            setUpdatingDWS(true);
            const response = await fetch(userBalanceBlocksUpdateEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    start_block: start_block,
                    end_block: end_block,
                }),
            });

            if (response.ok) {
                alert('DWS updated successfully.');
            } else {
                const errorText = await response.text();
                throw new Error(`Error ${response.status}: ${errorText}`);
            }
        } catch (err) {
            setError(err);
            alert(`Error: ${err.message}`);
        } finally {
            setUpdatingDWS(false);
        }
    };

    /**
     * 7) handleDistribute() -> triggers the actual distribution.
     */
    const handleDistribute = async () => {
        try {
            const allAreZero = data.every(item => item.user_undistributed_usdi <= 0);
            if (allAreZero) {
                alert('All User Undistributed USDI Are 0, cannot distribute.');
            } else {
                setDistribute(true);
                const response = await fetch(distributeUsdiEndpoint, {
                    method: 'POST',
                });

                if (response.ok) {
                    fetchData();
                } else {
                    throw new Error('Failed to trigger distribution');
                }
            }
        } catch (err) {
            setError(err);
        } finally {
            setDistribute(false);
        }
    };

    /**
     * 8) handleCalculate() -> triggers the "calculation-trigger" endpoint
     *    with user-provided block_number & final_distribution_amount.
     */
    const handleCalculate = async () => {
        let block_number = prompt('Enter Block Number:');
        let final_distribution_amount = prompt('Enter Final Distribution Amount:');

        // Remove commas, parse to number
        block_number = parseInt(block_number.replace(/,/g, ''), 10);
        final_distribution_amount = parseFloat(final_distribution_amount.replace(/,/g, ''));

        if (!block_number || !final_distribution_amount) {
            alert('Both fields are required!');
            return;
        }

        try {
            setCalculating(true);
            const response = await fetch(calculationTriggerEndpoint, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    block_number: Number(block_number),
                    final_distribution_amount: Number(final_distribution_amount),
                }),
            });

            if (response.ok) {
                // Re‐fetch after successful calculation
                fetchData();
            } else {
                throw new Error('Failed to calculate distribution');
            }
        } catch (err) {
            setError(err);
        } finally {
            setCalculating(false);
        }
    };

    /**
     * 9) Handle Loading/Error states, then display the table.
     */
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    let sortedData;
    if (selectedChain === 'solana') {
     // 1）复制一份 data, 按 balance_of_usdi 从大到小排序
    sortedData = data.slice().sort((a, b) => {
        const aBal = Number(a.balance_of_usdi) || 0;
        const bBal = Number(b.balance_of_usdi) || 0;
        return bBal - aBal;
    });

    // 2）给排序后的数据分配 userId: 排名第 1 的地址拿 userId=1，以此类推
    sortedData = sortedData.map((row, index) => ({
        ...row,
        userId: index + 1  // index 从 0 开始，所以+1
    }));
    } else {
        // 2) Ethereum 下按 userId (基于 addressMap) 排序
        sortedData = data
            .map((row) => ({
                ...row,
                userId: addressToUserIdMap[
                    row.address.toLowerCase().slice(0, 42)
                    ] || Infinity,
            }))
            .sort((a, b) => a.userId - b.userId);
    }
    return (
        <>
            <div className="button_wrapper">
                <h3>USDI Holders Info</h3>
                <button
                    className={`button ${updating ? 'updating' : ''}`}
                    onClick={handleUpdate}
                    disabled={updating}
                >
                    {updating ? 'Updating' : 'Update'}
                </button>
            </div>

            <div className="table-responsive noto">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th>User Unique ID</th>
                        <th>Address</th>
                        <th>Current Block Number</th>
                        <th>Last Block Number</th>
                        <th>Status</th>
                        <th>BNP</th>
                        <th>
                            Cum DWS
                            <FontAwesomeIcon
                                icon={faSyncAlt}
                                spin={updatingDWS}
                                onClick={handleUpdateDWS}
                                className="update-icon"
                            />
                        </th>
                        <th>Balance of USDI</th>
                        <th>DWS</th>
                        <th>New Cumulative DWS</th>
                        <th>CumDWS Weight %</th>
                        <th>
                            User Undistributed USDI
                            <FontAwesomeIcon
                                icon={faSyncAlt}
                                spin={calculating}
                                onClick={handleCalculate}
                                className="update-icon"
                            />
                        </th>
                        <th>
                            Cumulative Distributed USDI
                            <FontAwesomeIcon
                                icon={faSyncAlt}
                                spin={distributing}
                                onClick={handleDistribute}
                                className="update-icon"
                            />
                        </th>
                        <th>New Cumulative DWS After Distribution</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedData.map((row, index) => (
                        <tr key={index}>
                            <td>{row.userId !== Infinity ? row.userId : 'N/A'}</td>
                            <td>{row.address}</td>
                            <td>{digitFormat(row.current_block_number)}</td>
                            <td>{digitFormat(row.last_block_number)}</td>
                            <td>{row.status}</td>
                            <td>{digitFormat(row.bnp)}</td>
                            <td
                                onMouseEnter={() => {
                                    setHoveredAddress(row.address);
                                    if (!historicalData[row.address]) {
                                        fetchHistoricalData(row.address);
                                    }
                                }}
                                onMouseLeave={() => setHoveredAddress(null)}
                                style={{position: 'relative', cursor: 'pointer'}}
                            >
                                {digitFormat(row.cum_dws)}
                                {hoveredAddress === row.address &&
                                    historicalData[row.address] && (
                                        <div className="popup">
                                            <table className="table table-sm">
                                                <thead>
                                                <tr>
                                                    <th>Block Number</th>
                                                    <th>Cum DWS</th>
                                                    <th>Balance</th>
                                                    <th>DWS</th>
                                                    <th>BNP</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {historicalData[row.address].map(
                                                    (histRow, idx) => (
                                                        <tr key={idx}>
                                                            <td>{digitFormat(histRow.block_number)}</td>
                                                            <td>{digitFormat(histRow.cum_dws)}</td>
                                                            <td>{moneyFormat(histRow.balance)}</td>
                                                            <td>{digitFormat(histRow.dws)}</td>
                                                            <td>{digitFormat(histRow.bnp)}</td>
                                                        </tr>
                                                    )
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                            </td>
                            <td>{moneyFormat(row.balance_of_usdi)}</td>
                            <td>{digitFormat(row.dws)}</td>
                            <td>{digitFormat(row.new_cum_dws)}</td>
                            <td>{moneyFormat4decimals(row.cum_dws_weight_percent)}%</td>
                            <td>{moneyFormat4decimals(row.user_undistributed_usdi)}</td>
                            <td>{moneyFormat4decimals(row.cum_distributed_usdi)}</td>
                            <td>0.00</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default HolderInfoTable;
