// raydium-config.js

import {Raydium, TxVersion, parseTokenAccountResp} from '@raydium-io/raydium-sdk-v2'
import {Connection, clusterApiUrl, PublicKey} from '@solana/web3.js'
import {TOKEN_PROGRAM_ID, TOKEN_2022_PROGRAM_ID} from '@solana/spl-token'

// const RPC_URL = "https://rpc.ankr.com/solana/80465c767c6a5751c4cadf0778b6917d26c2d0a9ae583d3616ffaae71191a7c4"
const RPC_URL = "https://solitary-boldest-thunder.solana-mainnet.quiknode.pro/7e14722814e9fc29ad81532c2da66c2ffbe3781f"
const raydiumConfig = {
    // 公钥
    ownerAddress: null, // 将在外部赋值 new PublicKey(...)

    // 注意：不再存一个 wallet 对象，而是存 signAllTransactions
    signAllTransactions: null,

    // 连接和其他配置
    connection: new Connection(RPC_URL ),
    txVersion: TxVersion.V0,
    cluster: 'mainnet',

    raydium: null, // load() 后会赋值

    async initSdk(params = {}) {
        // 如果已加载过，就直接返回
        if (this.raydium) return this.raydium

        // 确保 ownerAddress 和 signAllTransactions 都已由外部赋值

        if (!this.ownerAddress) {
            throw new Error('raydiumConfig: ownerAddress not set!')
        }
        console.log("inside raydium initSDK, raydium.signalltransactions", this.signalltransactions)
        if (!this.signAllTransactions) {
            throw new Error('raydiumConfig: signAllTransactions not set!')
        }

        // 使用 Raydium.load() 加载
        this.raydium = await Raydium.load({
            owner: this.ownerAddress,               // 必须是 PublicKey 或 Keypair
            signAllTransactions: this.signAllTransactions,  // 函数
            connection: this.connection,
            cluster: this.cluster,
            disableFeatureCheck: true,
            disableLoadToken: !params.loadToken,
            blockhashCommitment: 'finalized',
        })

        return this.raydium
    }
}

export default raydiumConfig
