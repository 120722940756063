// components/Table/SmartContractTable.js
import React, {useEffect, useState} from 'react';
import {digitFormat} from '../utils';

const SmartContractTable = ({selectedChain, onChainSelect}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    'https://usdi-api-jlukzyz7wa-ew.a.run.app/chain_and_smart_contract_master_table'
                );

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div>
            {/* 顶部两个 Tab，仅用于显示 Ethereum / Solana，并切换样式 */}
            <ul className="nav nav-tabs" style={{borderBottom: 'none'}}>
                <li
                    className="nav-item"
                    id="ethereum-tab"
                    style={{
                        background: '#071112',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '0'

                    }}
                >
                    <button
                        id="ethereum-tab-lower"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '10px',
                            paddingTop: '10px',
                            marginBottom: '-5px',
                            transition: 'color 0.2s ease-in-out',
                            color: selectedChain === 'eth' ? 'black' : 'white',
                        }}
                        className={`nav-link ${selectedChain === 'eth' ? 'active' : ''}`}
                        onClick={() => onChainSelect('eth')}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.color = 'rgba(255,255,255,0.7)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.color =
                                selectedChain === 'eth' ? 'black' : 'white';
                        }}
                    >
                        Ethereum
                    </button>
                </li>

                {/* Solana Tab */}
                <li
                    className="nav-item"
                    id="solana-tab"
                    style={{
                        background: '#071112',
                        marginLeft: '-10px',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '0'
                    }}
                >
                    <button
                        id="solana-tab-lower"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: '10px',
                            paddingTop: '10px',
                            marginBottom: '-5px',
                            transition: 'color 0.2s ease-in-out',
                            color: selectedChain === 'solana' ? 'black' : 'white',
                        }}
                        className={`nav-link ${selectedChain === 'solana' ? 'active' : ''}`}
                        onClick={() => onChainSelect('solana')}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.color = 'rgba(255,255,255,0.7)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.color =
                                selectedChain === 'solana' ? 'black' : 'white';
                        }}
                    >
                        Solana
                    </button>
                </li>
            </ul>

            <div className="table-responsive noto">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th>blockchain</th>
                        <th>tokensmartcontractaddress</th>
                        <th>totalsupply</th>
                        <th>lockedaddress1</th>
                        <th>lockedaddress2</th>
                        <th>lockedaddress3</th>
                        <th>lockedaddress4</th>
                        <th>lockedaddress5</th>
                        <th>lockedaddress6</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{row.blockchain}</td>
                            <td>{row.tokensmartcontractaddress}</td>
                            <td>{digitFormat(row.totalsupply)}</td>
                            <td>{row.lockedaddress1}</td>
                            <td>{row.lockedaddress2}</td>
                            <td>{row.lockedaddress3}</td>
                            <td>{row.lockedaddress4}</td>
                            <td>{row.lockedaddress5}</td>
                            <td>{row.lockedaddress6}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SmartContractTable;
