import React, {useState, useEffect} from 'react';
import styles from './Swap.module.css';
import LineChart from "./linechart/LineCharts";
import UserHeader from "./header/UserHeader";
import usdiImg from "./static_files/USDi.png";
import wireTransferImg from "./static_files/wire-transfer.svg";
import copyImg from "./static_files/copy.svg";
import copyHoverImg from "./static_files/copy-hover.svg";
import BinanceImg from "./static_files/Binance.svg";
import EthereumImg from "./static_files/Ethereum.svg";
import TronImg from "./static_files/Torn.svg";
import SolanaImg from "./static_files/Solana.svg";
import approxImg from "./static_files/Text.svg";
import arrowImg from "./static_files/Arrow.svg";
import enterAmountImg from "./static_files/Enter an amount.svg"
import {fetchPortfolioTrackRecordsRoundFourDigit} from "./linechart/fetchDailyPortfolioData";
import {useAuth} from "../AuthContext";

const useWindowSize = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
};

const Swap = () => {
    const [usdiValue, setUsdiValue] = useState(null);
    const [usdiHoldersData, setUsdiHoldersData] = useState();
    const [availableUSDI, setAvailableUSDI] = useState(null);
    const [cumulativeInterest, SetCumulativeInterest] = useState(0);
    const [selectedTab, setSelectedTab] = useState('deposit');
    const [ImpliedApy, setImpliedApr] = useState([]);
    const {gatewayUserData} = useAuth();
    const currentUserData = gatewayUserData;
    const [clicked, setClicked] = useState(false);
    const [hoveredButton, setHoveredButton] = useState(null);

    const windowWidth = useWindowSize();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Promise.any([
                    fetchPortfolioTrackRecordsRoundFourDigit().catch(e => null)
                ]);
                if (data) {
                    const maxApy = Math.max(data.impliedApr, data.ninetyDayApy, data.thirtyDayApy);
                    setUsdiValue(data.totalValue / data.usdiInCirculation);
                    setImpliedApr(maxApy);
                } else {
                    console.error("Failed to fetch one or more data endpoints");
                }

                const response = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/usdi_holders_info_table');
                if (!response.ok) throw new Error('Network response was not ok');
                const result = await response.json();
                setUsdiHoldersData(result);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (usdiHoldersData && currentUserData) {
            const userAddresses = [
                currentUserData.bsc_receive_address,
                currentUserData.ethereum_receive_address,
                currentUserData.tron_receive_address,
                currentUserData.solana_receive_address
            ].filter(Boolean);

            const uniqueAddresses = [...new Set(userAddresses.map(a => a.toLowerCase()))];

            let totalBalance = 0;
            let totalDistributedUsdi = 0;
            for (const holder of usdiHoldersData) {
                const holderAddress = holder.address.toLowerCase();
                if (uniqueAddresses.includes(holderAddress)) {
                    const balance = parseFloat(holder.balance_of_usdi) || 0;
                    totalBalance += balance;
                    const DistributedUsdi = parseFloat(holder.cum_distributed_usdi) || 0;
                    totalDistributedUsdi += DistributedUsdi;
                }
            }

            setAvailableUSDI(totalBalance);
            SetCumulativeInterest(totalDistributedUsdi);
        }
    }, [usdiHoldersData, currentUserData]);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        setClicked(true);
        setTimeout(() => setClicked(false), 500);
    };

    const formatAddress = (address) => {
        if (!address) return '';
        // If full address is something like: 0xfB258D68EE... etc
        // Based on screen size, truncate:
        if (windowWidth < 563) {
            // Very narrow screens: show even shorter
            // Example: show first 6 chars + "....." + last 6 chars
            return address.slice(0, 6) + '.....' + address.slice(-6);
        } else if (windowWidth >= 768 && windowWidth <= 1346) {
            // Between 768px and 1346px:
            // Show 0xfB258D68.....04530C254B as requested
            // We'll take first 10 chars and last 10 chars for example
            return address.slice(0, 10) + '.....' + address.slice(-10);
        } else {
            // Larger screens: show full address
            return address;
        }
    };

    const renderCopyButton = (id, address) => (
        <div
            className={`${styles.copyBtn} ${clicked ? 'clicked' : ''}`}
            onMouseEnter={() => setHoveredButton(id)}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={() => {
                handleCopy(address);
                setHoveredButton(null);
            }}
        >
            <img src={hoveredButton === id ? copyHoverImg : copyImg} alt="copy"/>
        </div>
    );

    return (
        <div style={{backgroundColor: '#04070D', height: '100vh'}}>
            <UserHeader/>
            <div className={styles.container}>
                {/* Left Container */}
                <div className={styles.leftContainer}>
                    <div className={styles.balanceSection}>
                        {/*<div className={styles.balanceBox}>*/}
                        {/*    <h2 className={styles.availableBalance}>*/}
                        {/*        {availableUSDI && usdiValue*/}
                        {/*            ? `$${(availableUSDI * usdiValue).toLocaleString(undefined, {*/}
                        {/*                minimumFractionDigits: 2,*/}
                        {/*                maximumFractionDigits: 2*/}
                        {/*            })}`*/}
                        {/*            : '--'}*/}
                        {/*    </h2>*/}
                        {/*    <img src={arrowImg} alt="arrow"/><p className={styles.greenGain}> 49.32%</p>*/}
                        {/*</div>*/}
                        <div className={styles.chartWrapper}>
                            <LineChart chartBgColor="#060F11" maxWidth="481px" availableUSDI={availableUSDI}
                                       usdiValue={usdiValue}/>

                        </div>
                        <div className={styles.usdiAPY}>
                            <div className={styles.innerUsdiApy}>
                                <div style={{textAlign: 'right', width: '144px'}}>
                                    <div className={styles.usdiAPYtitle}>Balance</div>
                                </div>
                                <div className={styles.usdiAPYtitle}>APY</div>
                            </div>
                        </div>

                        <div className={styles.usdiInfo}>
                            <div className={styles.usdiHeader}>
                                <div className={styles.usdiItemLeft}>
                                    <img className={styles.usdiImage} src={usdiImg} alt="USDi"/>
                                    <p>USDi</p>
                                </div>
                                <div className={styles.innerUsdiApy}>
                                    <div className={styles.usdiBox}>
                                        <div style={{height: '7px', background: '#060F11'}}></div>
                                        <div
                                            className={styles.usdiValue2}>{availableUSDI ? availableUSDI.toFixed(2) : '--'}</div>
                                        <div style={{background: '#060F11'}}>
                                            {availableUSDI && usdiValue ? (
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'end',
                                                    background: '#060F11'
                                                }}>
                                                    <img style={{background: '#060F11',}} src={approxImg} alt="≈"/>
                                                    <span
                                                        className={styles.usdiValue}>{`$${(availableUSDI * usdiValue).toFixed(2)}`}</span>
                                                </div>
                                            ) : (
                                                '--'
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.usdiItemRight}>
                                        <h5>
                                            {ImpliedApy !== undefined && ImpliedApy !== null && !isNaN(ImpliedApy)
                                                ? `${Number(ImpliedApy).toFixed(2)}%`
                                                : '--'}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.lineSeparator}></div>
                            <div className={styles.usdiStats}>
                                <div className={styles.usdiDataWrapper}>
                                    <h4>
                                        {usdiValue
                                            ? `${parseFloat(usdiValue).toFixed(2)} `
                                            : '--'}
                                    </h4>
                                    <p>NAV per USDI</p>
                                </div>
                                <div className={styles.usdiDataWrapper}>
                                    <h4>
                                        {cumulativeInterest ? (
                                            <>
                                                {parseFloat(cumulativeInterest).toFixed(2)}
                                                <span className={styles.usdiLabel}> USDi</span>
                                            </>
                                        ) : (
                                            '--'
                                        )}
                                    </h4>
                                    <p>Cumulative Interest</p>
                                </div>

                                <div className={styles.usdiDataWrapper}>
                                    <h4>
                                        {ImpliedApy && availableUSDI && usdiValue
                                            ? `$${(0.01 * ImpliedApy * availableUSDI * usdiValue / 12).toFixed(2)}`
                                            : '--'}
                                    </h4>
                                    <p>30-Day Interest</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right Container */}
                <div className={styles.rightContainer}>
                    <div className={styles.tabs}>
                        <button
                            className={selectedTab === 'deposit' ? styles.activeTab : ''}
                            onClick={() => setSelectedTab('deposit')}
                        >
                            Deposit
                        </button>
                        <button
                            className={selectedTab === 'withdraw' ? styles.activeTab : ''}
                            onClick={() => setSelectedTab('withdraw')}
                        >
                            Withdraw
                        </button>
                        <button
                            className={selectedTab === 'swap' ? styles.activeTab : ''}
                            onClick={() => setSelectedTab('swap')}
                        >
                            Swap
                        </button>
                    </div>

                    {selectedTab === 'deposit' && (
                        <div className={styles.depositSection}>
                            <div className={styles.otherMethods}>
                                <h3>Crypto Network</h3>
                                <div className={styles.methodItem}>
                                    <div className={styles.methodItemLeft}>
                                        <img src={BinanceImg} alt="BinanceImg"/>
                                        <div className={styles.walletAddress}>
                                            <h4>Binance Smart Chain</h4>
                                            <h5>{formatAddress(currentUserData.bsc_receive_address)}</h5>
                                        </div>
                                    </div>
                                    {renderCopyButton('bsc', currentUserData.bsc_receive_address)}
                                </div>

                                <div className={styles.methodItem}>
                                    <div className={styles.methodItemLeft}>
                                        <img src={EthereumImg} alt="EthereumImg"/>
                                        <div className={styles.walletAddress}>
                                            <h4>Ethereum</h4>
                                            <h5>{formatAddress(currentUserData.ethereum_receive_address)}</h5>
                                        </div>
                                    </div>
                                    {renderCopyButton('eth', currentUserData.ethereum_receive_address)}
                                </div>

                                <div className={styles.methodItem}>
                                    <div className={styles.methodItemLeft}>
                                        <img src={TronImg} alt="TronImg"/>
                                        <div className={styles.walletAddress}>
                                            <h4>Tron</h4>
                                            <h5>{formatAddress(currentUserData.tron_receive_address)}</h5>
                                        </div>
                                    </div>
                                    {renderCopyButton('tron', currentUserData.tron_receive_address)}
                                </div>

                                <div className={styles.methodItem}>
                                    <div className={styles.methodItemLeft}>
                                        <img src={SolanaImg} alt="SolanaImg"/>
                                        <div className={styles.walletAddress}>
                                            <h4>Solana</h4>
                                            <h5>{formatAddress(currentUserData.solana_receive_address)}</h5>
                                        </div>
                                    </div>
                                    {renderCopyButton('sol', currentUserData.solana_receive_address)}
                                </div>
                            </div>

                            <div className={`${styles.otherMethods} ${styles.otherMethods2}`}>
                                <h4>Other Methods</h4>
                                <div className={styles.methodItem}>
                                    <div className={styles.methodItemLeft}>
                                        <img src={wireTransferImg} alt="wireTransferImg"/>
                                        <h4>Wire Transfers</h4>
                                    </div>
                                    <div className={styles.methodItemRight}>
                                        <h5>1-3 Days</h5>
                                        <h5>$10.00 Fee</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedTab === 'withdraw' && (

                        <div className={styles.withdrawSection}>
                            <div className={styles.withdrawSectionMiddle}>
                                <span className={styles.withDrawBig}>0</span> <span
                                className={styles.withDrawGrey}>USD</span>
                                <div className={styles.withDrawAmount}>
                                    {availableUSDI && usdiValue
                                        ? `$${(availableUSDI * usdiValue).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })} Available`
                                        : '$0.00 Available'}
                                </div>
                                <div className={styles.methodItem}>
                                    <div onClick={() => alert("Developing")} className={styles.withDrawItemLeft}>
                                        <img className={styles.withdrawImg} src={wireTransferImg}
                                             alt="wireTransferImg"/>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            background: "#060F11"
                                        }}>
                                            <h4>Transfers to</h4>
                                            <h5> Wire Transfer</h5>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div onClick={() => alert("Developing")} className={styles.withDrawItemRight}>
                                            <h5>1-3 biz days</h5>
                                            <h5>Fee apply</h5>
                                        </div>
                                        <div className={styles.arrow}>
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button onClick={() => alert("Developing")}>
                                <img src={enterAmountImg} alt="Enter Amount"/>
                            </button>
                        </div>


                    )}

                    {selectedTab === 'swap' && (
                        <div className={styles.withdrawSection}>
                            <div className={styles.swapSectionMiddle}>

                            </div>

                            <button onClick={() => alert("Developing")}>
                                <img src={enterAmountImg} alt="Enter Amount"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

}

export default Swap;